import React from 'react';

const apiKey = "",
    secret = "",
    url = "https://api-partners.signuscorp.com",
    convertGet = (params) => {

        const str = [];

        for (const key of Object.keys(params)) {

            if (typeof (params[key]) !== 'object') {
                str.push(`${key}=${params[key]}`);
            } else if (typeof (params[key]) === 'object' && params[key] !== null) {

                for (const key2 of Object.keys(params[key])) {

                    if (typeof (params[key][key2]) !== 'object') {
                        str.push(`params[${key2}]=${params[key][key2]}`);
                    } else {
                        for (const par of params[key][key2]) {
                            str.push(`params[${key2}]=${par}`);
                        }
                    }
                }
            }
        }

        return str.join('&');
    },
    headers = { 'Content-Type': 'application/json', 'Authorization': "Basic aGc0UDRLSkBINDYlOjhFOVpqI0E5MzhzQQ==" },
    post = (params) => new Promise((resolve, reject) => {

        fetch(`${url}${params.url}`, { method: 'post', headers, body: JSON.stringify(params.params) })
            .then(response => response.json())
            .then(data => {

                if (data.code != 200)
                    return reject(data);

                return resolve(data);
            });
    }),
    put = (params) => new Promise((resolve, reject) => {

        fetch(`${url}${params.url}`, { method: 'put', headers, body: JSON.stringify(params.params) })
            .then(response => response.json())
            .then(data => {

                if (data.code != 200)
                    return reject(data);

                return resolve(data);
            });
    }),
    get = (params) => new Promise((resolve, reject) => {

        fetch(`${url}${params.url}?${convertGet(params.params)}`, { headers })
            .then(response => response.json())
            .then(data => {

                if (data.code != 200)
                    return reject(data);

                return resolve(data);
            });
    });

export default { post, put, get };