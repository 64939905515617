import React from "react";
import Header from "../../components/header/Header";
import Slider from "../../components/slider/SliderTwo";
import About from "../../components/about/AboutTwo";
import Portfolio from "../../components/portfolio/PortfolioTwo";
import Skills from "../../components/skills/SkillsTwo";
import Video from "../../components/video/VideoTwo";
import Brand from "../../components/BrandAnimation";
import Testimonial from "../../components/testimonial/Testimonial";
import News from "../../components/news/NewsTwo";
import CallToAction from "../../components/calltoactions/CallToActionTwo";
import Footer from "../../components/footer/Footer";

const faq = [
  {
    title: "1. ¿Cómo recibo mi pago?",
    description: "La utilidad mensual recurrente será pagada el día quince (15) de cada mes, la cual corresponderá a la factura del mes anterior ya pagada por el cliente"
  },
  {
    title: "2. No tengo una cartera de cliente recurrente ¿Puedo convertirme en Partner? ",
    description: "El programa de Partners está desarrollado con la premisa que puedas ganar por negocio estándar concretado, así que tener una cartera de clientes activa es un facilitador para generar oportunidades, sin embargo no es un requisito indispensable puedes unirte al programa y conforme detectes oportunidades, ir registrándolas."
  },
  {
    title: "3. ¿Debo firmar contrato?",
    description: "Cada Partner representa una parte importante en este programa, por lo que con cada uno firmamos un acuerdo para concretar la categoría de Partner."
  },
  {
    title: "4. Qué pasa con mi información si ya no quiero ser parte del programa? ",
    description: "Toda la información almacenada en tu portal de Partner podrás extraerla en un documento en plano para que podamos dar de baja tu cuenta.  "
  },
  {
    title: "5. ¿Debo renovar mi membresía anualmente?",
    description: "No, cada categoría contempla un periodo de tiempo, que se renueva de manera automática anualmente, a menos que el Partner notifique la baja."
  },
  {
    title: "6. ¿Por qué ofrecer SignusCRM a mis clientes?",
    description: "SignusCRM es una solución Saas, práctica y fácil de usar, que al ser implementada bajo las necesidades de tus clientes les permites resolver múltiples problemas con los cuales se enfrentan en el proceso comercial y gestión empresarial"
  },
  {
    title: "7. ¿Debo contar con un ingeniero en tecnología para implementar SignusCRM?",
    description: "Tanto la implementación como el soporte, se provee inicialmente desde nuestra casa matriz. En caso de que cuentes con un perfil que podamos capacitar y certificar como Técnico Implementador SignusCRM, puedes en su momento aumentar tus ingresos al realizar el proceso de implementación y brindar soporte."
  },
  {
    title: "8. ¿Debo pagar alguna membresía para formar parter del Partners Program?",
    description: "El acceso a nuestro programa es gratuito."
  },
  {
    title: "9. ¿Estoy atado a alguna meta de ventas?",
    description: "Cada categoría de partner posee sus propios beneficios y requisitos de prospectos a trabajar. Debes inicialmente ingresar como Business Partner y aumentar de categoría progresivamente."
  }
]

const HomeTwo = () => {
  return (
    <div className="home-two">
      <Header />
      {/* End Header Section */}

      <Slider />
      {/* End Slider Section */}

      <Portfolio />

      {/*}
      <About />
      <Skills />
      {*/}

      {/*}
      <div className="shane_tm_section">
        <div className="shane_tm_partners">
          <div className="container">
            <div className="partners_inner">
              <Brand />
            </div>
          </div>
        </div>
      </div>
    { */}

      <Video />

      {/*}
      <News />
      {*/}

      <div className="shane_tm_intro">
        <div className="shane_tm_feature section-separaton">
          <div className="intro_title">
            <h3>Preguntas frecuentes</h3>
          </div>
          <div className="container fluid">
            <div className="row">
              {faq.map(content => (
                <div
                  className="col-12"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <div className="single-features">
                    <h4>{content.title}</h4>
                    <p>
                      {content.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Testimonial />

      <CallToAction />

      {/*}
      <About />
      {*/}

      <Footer />
    </div>
  );
};

export default HomeTwo;
