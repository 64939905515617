import React, { useState } from "react";
import Modal from "react-modal";
import Social from "../Social";

Modal.setAppElement("#root");

const Testimonial = () => {

  return (
    <div className="shane_tm_section" id="news">
      <div className="shane_tm_news">
        <div className="container">
          <div className="shane_tm_title">
            <span>Testimoniales</span>
            <h3>¿Qué dicen nuestros Partners?</h3>
          </div>
          <div className="news_list">
            <ul>
              <li data-aos="fade-right" data-aos-duration="1200">
                <div className="list_inner">
                  <div style={{ height: "255px", textAlign: "justify", overflow: "auto" }}>
                    Las empresas que brindamos servicios dentro de la transición digital, debemos colaborar mutuamente para así acelerar la generación de valor hacia los negocios que están iniciando la transformación, implementando sistemas y tecnología que permita consolidar sus operaciones y detectar áreas de oportunidad de mejora, sostenibilidad y crecimiento. Ser partners de SignusCRM, nos ha permitido acelerar cambios positivos en diferentes industrias y mercados de forma ágil, aumentando a su vez nuestros ingresos.
                  </div>

                  <div className="details">
                    <h3 className="title">
                      Consultor Empresarial
                    </h3>
                    <img src="img/about/1.webp" style={{ marginTop: "5px", height: "50px", width: "50px", float: "left", marginRight: "10px", borderRadius: "5px" }} />
                    <p className="date">
                      Max Araya <span>20 May 2020</span>
                    </p>
                  </div>
                </div>
              </li>

              <li data-aos="fade-right" data-aos-duration="1200">
                <div className="list_inner">
                  <div style={{ height: "255px", textAlign: "justify", overflow: "auto" }}>
                    <audio controlsList="nodownload" controls style={{ marginTop: "100px" }}>
                      <source src="/video/testimonial_benito_fontiveros.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </audio>
                  </div>

                  <div className="details">
                    <h3 className="title">
                      Asesor de tecnología
                    </h3>
                    <img src="img/about/2.webp" style={{ marginTop: "5px", height: "50px", width: "60px", float: "left", marginRight: "10px", borderRadius: "5px" }} />
                    <p className="date">
                      Benedicto Fontiveros <span>16 Feb 2023</span>
                    </p>
                  </div>
                </div>
              </li>

              <li data-aos="fade-right" data-aos-duration="1200">
                <div className="list_inner">
                  <div style={{ height: "255px", textAlign: "justify", overflow: "auto" }}>
                    <video height="240" width="300" controlsList="nodownload" controls>
                      <source src="/video/testimonial_eduardo_lopez.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>

                  <div className="details">
                    <h3 className="title">
                      Asesor de tecnología
                    </h3>
                    <img src="img/about/3.webp" style={{ marginTop: "5px", height: "50px", width: "50px", float: "left", marginRight: "10px", borderRadius: "5px" }} />
                    <p className="date">
                      Eduardo López <span>04 Abr 2022</span>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
