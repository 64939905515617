import React from "react";
import { useForm } from "react-hook-form";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.target.reset();

    const Message = {
      fromEmail: 'no-reply@signuscorp.com',
      fromName: 'Signus Partners 🙋',
      TO: [{
        Name: 'Consulta vistante Partner',
        Email: 'partners@signuscorp.com'
      }],
      BCC: [],
      templateID: 4735656,
      Subject: `😃 Recibimos una consuta de ${data.name}`,
      TemplateLanguage: true,
      Variables: {
        name: data.name,
        email: data.email,
        phone: data.phone,
        subject: data.subject
      }
    }

    fetch('https://api-rest.signuscorp.com/resources/sendMail', {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic U1pwb3J2dDJIY0s3OlFCakVWZkJ2ZDBubA=="
      },
      method: "POST",
      body: JSON.stringify(Message)
    })
      .then(response => response.json())
      .then(response => {
        if (response.code == 200) {
          alert("Recibimos tu consulta, pronto estaremos en contacto.");
        }
      });
  };

  return (
    <>
      <form className="contact_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="first">
          <ul>
            <li>
              <input
                {...register("name", { required: true })}
                type="text"
                placeholder="Nombre"
              />
              {errors.name && errors.name.type === "required" && (
                <span>El nombre es requerido</span>
              )}
            </li>
            {/* End first name field */}

            <li>
              <input
                {...register("phone", { required: false })}
                type="text"
                placeholder="Teléfono"
              />
            </li>

            <li>
              <input
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
                type="email"
                placeholder="Email"
              />
              {errors.email && <span role="alert">{errors.email.message}</span>}
            </li>
            {/* End email name field */}

            <li>
              <textarea
                {...register("subject", { required: true })}
                placeholder="Consulta"
              ></textarea>
              {errors.subject && <span>Ingrese su consulta.</span>}
            </li>
            {/* End subject  field */}
          </ul>
        </div>

        <div className="tokyo_tm_button">
          <button type="submit" className="white-fill-bg fill-black">
            Enviar
          </button>
        </div>
        {/* End tokyo_tm_button */}
      </form>
      {/* End contact */}
    </>
  );
};

export default Contact;
