import React, { useState } from "react";
import Modal from "react-modal";
import Map from "../Map";
import RegisterToCRM from "../RegisterToCRM";
import Social from "../Social";

Modal.setAppElement("#root");

const Register = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <div>
      <a onClick={toggleModalOne} className="btn_steps_register">
        Registro
      </a>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="shane_tm_mobalbox_contact">
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              {/* Start modal content */}
              <div className="title">
                <h3>Regístrate</h3>
                <h5>Completa el formulario de registro, recibirás un correo en tu bandeja de entrada para activar tu perfil, podrás registrar las oportunidades y observar su avance. </h5>
              </div>
              {/* End title */}

              <div className="wrapper">
                <div className="left">
                  <div className="fields">
                    <RegisterToCRM />
                  </div>
                </div>
                {/* End left */}
                <div className="right">
                  <div className="map_wrap">
                    <video autoplay controls id="videoSteps" controlsList="nodownload" >
                      <source src="/video/4_sencillos_pasos.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                {/* End right */}
              </div>
              {/* End wrapper */}

              <div className="short_info">
                <ul>
                  <li>
                    <div className="list_inner">
                      <img
                        className="svg"
                        src="img/svg/location.svg"
                        alt="location"
                      />
                      <p>16 calle 4-53 z.10, Guatemala.</p>
                    </div>
                  </li>
                  {/* End silgle address */}

                  <li>
                    <div className="list_inner">
                      <img
                        className="svg"
                        src="img/svg/phone.svg"
                        alt="phone"
                      />
                      <p>
                        <a href="tel:+50224444722">+502 2444 4722</a>
                      </p>
                    </div>
                  </li>
                  {/* End silgle address */}

                  <li>
                    <div className="list_inner">
                      <img
                        className="svg"
                        src="img/svg/share.svg"
                        alt="share"
                      />
                      <Social />
                    </div>
                  </li>
                  {/* End silgle address */}
                </ul>
              </div>
              {/* End modal conetent */}
            </div>
          </div>
          {/* End box inner */}
        </div>
        {/* End modalbox news */}
      </Modal>
      {/* End modal */}
    </div>
  );
};

export default Register;
