import React from "react";
import { useForm } from "react-hook-form";
import Api from "../api/api-partners";

const RegisterToCRM = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [countries, setCountries] = React.useState([]);
  const [business, setBusiness] = React.useState([]);

  const getResources = (next) => {
    Api.get({ url: '/forms/resources', params: {} })
      .then(data => {
        setCountries(data.data.countries);
        setBusiness(data.data.business);
        next();
      })
      .catch(err => {

      });
  }

  const addPartner = (data) => {

    data.SEND_MAIL = {
      fromEmail: 'info@signuscorp.com',
      fromName: 'Signus Partners 💸',
      TO: [{
        Name: data.nombre,
        Email: data.email
      }],
      BCC: [{
        Name: 'Signus Info',
        Email: 'partners@signuscorp.com'
      }],
      templateID: 4733196,
      subject: `📧 Hola ${data.nombre}, activa tu perfil.`,
      vars: {
        _LINK_PARTNER: true
      }
    }

    fetch('https://api-rest.signuscorp.com/webhook/eyJpZF9lbXByZXNhIjoxLCJ3ZWJob29rIjoiNjQzOGM2NDI5Mzg2MiJ9', {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => {
        if (response.code == 200) {
          alert("¡Te damos la bienvenida al programa! Pronto estaremos en contacto. 🎉✌️🎊😃");
          window.dataLayer.push({'event_name':'send_on_header_register_button'});
        }

        Api.post({ url: '/partner/add', params: response });
      });
  }

  React.useEffect(() => {
    getResources();
  }, []);

  const onSubmit = (data, e) => {
    e.target.reset();
    addPartner(data);
  };

  return (
    <>
      <form className="partners_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="first">
          <ul>
            <li>
              <input
                {...register("nombre", { required: true })}
                type="text"
                placeholder="Nombre"
              />
              {errors.nombre && errors.nombre.type === "required" && (
                <span>El nombre es requerido</span>
              )}
            </li>

            <li>
              <input
                {...register("apellido", { required: true })}
                type="text"
                placeholder="Apellido"
              />
              {errors.apellido && errors.apellido.type === "required" && (
                <span>El apellido es requerido</span>
              )}
            </li>

            <li>
              <input
                {...register(
                  "email",
                  {
                    required: "El correo es requerido",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "El formato ingresado no es correcto",
                    },
                  },
                  { required: true }
                )}
                type="email"
                placeholder="Correo electrónico"
              />
              {errors.email && <span role="alert">{errors.email.message}</span>}
            </li>

            <li>
              <input
                {...register("telefono", { required: true })}
                type="text"
                placeholder="Teléfono"
              />
              {errors.name && errors.name.type === "required" && (
                <span>El teléfono es requerido</span>
              )}
            </li>

            <li>
              <label style={{ color: "#999" }}>País</label>
              <select
                {...register("pais", { required: true })}
              >
                <option selected disabled></option>
                {countries.map(country => (
                  <>
                    <option value={country.opcion}>{country.nombre}</option>
                  </>
                ))}
              </select>
              {errors.pais && errors.pais.type === "required" && (
                <span>El país es requerido</span>
              )}
            </li>

            <li>
              <label style={{ color: "#999" }}>Gíro de negocio</label>
              <select
                {...register("giro", { required: true })}
              >
                <option selected disabled></option>
                {business.map(buss => (
                  <>
                    <option value={buss.opcion}>{buss.nombre}</option>
                  </>
                ))}
              </select>
              {errors.giro && errors.giro.type === "required" && (
                <span>El giro de negocio es requerido</span>
              )}
            </li>
          </ul>
        </div>

        <div className="tokyo_tm_button">
          <button type="submit" className="white-fill-bg fill-black">
            Enviar
          </button>
        </div>
        {/* End tokyo_tm_button */}
      </form>
      {/* End contact */}
    </>
  );
};

export default RegisterToCRM;
