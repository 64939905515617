import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Gallery, Item } from "react-photoswipe-gallery";
import Parser from 'html-react-parser';
import Register from '../calltoactions/Register'

const tabList = ["1", "2", "3", "4"];

const tabListContent = [
  {
    title: "Hazte Partner",
    description: "Completa el formulario para hacerte Partner.",
    icon: "/img/about/hazte_partner.gif",
    content: "Completa los datos de registro. Esto nos permitirá conocerte mejor y brindarte un trato personalizado.",
  },
  {
    title: "Refiere oportunidades",
    description: "Registra oportunidades en tu perfil personal.",
    icon: "/img/about/hazte_partner.gif",
    content: "Para tus clientes, que necesitan acelerar sus ventas y vender más.",
  },
  {
    title: "Nosotros vendemos",
    description: "Déjanos el seguimiento para convertir tus oportunidades.",
    icon: "/img/about/hazte_partner.gif",
    content: `Ofrecemos un CRM flexible, amigable y parametrizado según los procesos de tus clientes. <br/><br/>
    Consolida y administra los leads generados a través de formularios de canales digitales y redes sociales.<br/><br/>
    Aceleramos y estandarizamos el proceso comercial de tus clientes aumentando así su probabilidad de ventas.<br/><br/>
    Integramos SignusCRM a otras soluciones como ERP y factura electrónica, brindado así un ecosistema asociado de trabajo.`,
  },
  {
    title: "¡Tú ganas!",
    description: "Obten ganancias al concretar la parametrización.",
    icon: "/img/about/hazte_partner.gif",
    content: "Agrega SignusCRM a tu portafolio de servicios y genera ingresos adicionales.",
  },
];

let played = false;

const playVideo = () => {

  if (!played) {
    document.querySelector("#videoSteps").play();
    played = true; 
  }
}

const Portfolio = () => {
  return (
    <div className="shane_tm_section" id="about">
      <div className="shane_tm_portfolio">
        <div className="container">
          <div className="positon-relative">
            <div className="shane_tm_title">
              <div className="title_flex">
                <div className="left">
                  <span>¿Cómo funciona?</span>
                  <h3>¡<label style={{ color: "#000" }}>4</label> sencillos pasos!</h3>
                </div>
              </div>
            </div>
            {/* End shane_tm_title */}

            <div className="portfolio_filter" onMouseOver={playVideo}>
              <div className="shane_tm_about">
                <div className="container">
                  <div className="about_inner" style={{ alignItems: "top !important" }}>
                    <div className="left">
                      <video autoplay controls id="videoSteps" controlsList="nodownload" >
                        <source src="/video/4_sencillos_pasos.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <div className="right">
                      <Tabs>
                        <TabList>
                          {tabList.map((val, i) => (
                            <Tab style={{fontSize: "35px", fontWeight: "bold"}} key={i}>{val}</Tab>
                          ))}
                        </TabList>

                        <div className="portfolio_list has-effect" style={{ marginTop: "-50px" }}>
                          <Gallery>
                            {tabListContent.map((tabContent, i) => (
                              <TabPanel key={i}>
                                <div
                                  className="shane_tm_title"
                                  data-aos="fade-up"
                                  data-aos-duration="1200"
                                >
                                  <span>{tabContent.description}</span>
                                </div>
                                <div
                                  className="text"
                                  data-aos="fade-up"
                                  data-aos-duration="1200"
                                >
                                  <h3>{tabContent.title}</h3>
                                  <p>{Parser(tabContent.content)}</p>
                                </div>
                                <div
                                  className="shane_tm_button"
                                  data-aos="fade-up"
                                  data-aos-duration="1200"
                                >
                                  <Register />
                                </div>
                              </TabPanel>
                            ))}
                          </Gallery>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
